import { createContext, useContext, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { Bounce, toast } from 'react-toastify'
import { CHAT_EVENT } from '../constants/Chats.constants'
import { NotificationsQueryKey } from '../modules/User/useNotifications'
import WebsocketService from '../services/WebsocketService'

const WebSocketContext = createContext(null)

export const WebSocketProvider = ({ children }) => {
  const { user } = useSelector((state) => ({ user: state.User.user }))
  const [socket, setSocket] = useState(null)
  const queryClient = useQueryClient()

  useEffect(() => {
    const initializeSocket = async () => {
      if (user?.id) {
        try {
          let toastId = null
          let message = ''
          let type = null
          let position = 'bottom-right'

          const url = `${process.env.REACT_APP_BACKEND_URL}/notificationz`

          const connectedSocket = await WebsocketService.connectSocket({
            url,
            data: {
              userId: user?.id,
              token: sessionStorage.getItem('TSToken'),
            },
          })
          setSocket(connectedSocket)

          // Listen for generic events
          connectedSocket.onAny((event, data) => {
            // console.log(`Received event: ${event}`, data)
            // Handle generic events or dispatch to specific handlers

            if (!event.startsWith('chat__')) {
              queryClient.invalidateQueries([NotificationsQueryKey.notifications], { exact: false })

              queryClient.invalidateQueries([NotificationsQueryKey.notificationsInformation])
            }

            if (event.startsWith('quote__')) {
              message = data?.message ?? 'Quote event'
              type = 'warning'
              toastId = message
            }

            if (event.startsWith('get_ready__')) {
              message = data?.message ?? 'Get Ready event'
              type = 'warning'
              toastId = message
            }

            // if (event == WEBSOCKET_EVENT.WELCOME) {
            //   message = data?.message ?? 'Welcome'
            //   type = 'info'
            //   toastId = message
            //   position = 'top-right'
            // }

            if (message) {
              toast(message, {
                type,
                toastId,
                closeOnClick: true,
                position,
                autoClose: 150000, // 2.5 minutes
                transition: Bounce,
              })
              toastId = null
              message = null
              type = null
            }
          })

          // Handle specific events
          connectedSocket.on('receiveNotification', (data) => {
            console.log('Notification received:', data)
            toast.info(`New Notification: ${data.message}`)
          })

          connectedSocket.on('connect_error', (error) => {
            console.error('Connection error:', error)
            message = 'Connection error'
            type = 'error'
            toastId = message
          })

          // Add more event listeners as needed
        } catch (error) {
          console.error('Failed to connect WebSocket:', error)
          // toast.error('Failed to connect to the notification server.')
        }
      }
    }
    if (user?.id) initializeSocket()

    // Cleanup on unmount
    return () => {
      WebsocketService.disconnectSocket()
    }
  }, [user?.id])

  useEffect(() => {
    if (socket) {
      socket.emit(CHAT_EVENT.WELCOME, { message: 'Welcome' })
      socket.emit(CHAT_EVENT.JOIN_USER_ROOMS)
    }
  }, [socket])

  return <WebSocketContext.Provider value={socket}>{children}</WebSocketContext.Provider>
}

export const useWebSocket = () => {
  return useContext(WebSocketContext)
}

export const PERMISSIONS = {
  MANAGER: 1,
  BRANCH_MANAGER: 2,
  SALESPERSON: 3,
  CAN_ADD_INVENTORY: 25,
  CAN_EDIT_INVENTORY: 26,
  DO_NOT_ALLOW_TO_CREATE_QUOTES: 27,
  DO_NOT_ALLOW_TO_ADD_DOCUMENTS: 28,
  DO_NOT_ALLOW_TO_CREATE_GET_READY_FORMS: 29,
  SEE_WHOLESALE_INVENTORY: 30,
  TRUCK_SALES_FINANCIAL: 31,
  VIEW_ALL_QUOTES: 32,
  TRUCK_SALES_INVENTORY_ADDITIONAL_DATA: 52,
  CAN_LOGIN_TO_TRUCK_SALES: 45,
  TRUCK_SALES_INVENTORY_UPDATE: 53,
  CREATE_QUOTE_WITH_IN_TRANSIT_INVENTORY: 54,
  TRUCK_SALES_INVENTORY_ACCOUNTING: 55,
  TRUCK_SALES_INVENTORY_TITLE: 56,
}

export const CHAT_EVENT = {
  WELCOME: 'welcome',
  CONNECTED: 'connected',

  JOIN_CHAT: 'chat__join_chat',
  JOIN_USER_ROOMS: 'chat__join_user_rooms',
  JOINED_USER_ROOMS: 'chat__joined_user_rooms',
  GET_USER_QUOTE_CHATS: 'chat__get_user_quote_chats',
  USER_QUOTE_CHATS: 'chat__user_quote_chats',
  JOIN_ROOM: 'chat__join_room',
  LEAVE_ROOM: 'chat__leave_room',
  LEAVE_CHAT: 'chat__leave_chat',
  MANAGE_CHAT_ROOMS: 'chat__manage_chat_rooms',

  MARK_AS_READ: 'chat__mark_as_read',
  MARK_AS_READ_ACK: 'chat__mark_as_read_ack',
  MESSAGE: 'chat__message',
}

export const CHAT_TYPE = {
  QUOTE: 1,
}

export const CHAT_ROOM_ACTION = {
  JOIN: 'join',
  LEAVE: 'leave',
}

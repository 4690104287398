import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { jwtDecode } from 'jwt-decode'
import { lazy, Suspense, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { Loader } from './common/Loader'
import { GlobalModal } from './common/Modal/GlobalModal'
import { ProtectedRoute } from './common/ProtectedRoute'
import Toast from './common/Toast'
import { theme } from './config/theme'
import { WebSocketProvider } from './contexts/WebSocketContext'
import { changeTheme } from './redux/theme-slice'
import { setCurrentUser } from './redux/user-slice'
import { setAxiosInterceptors } from './utils/axiosConfig'
import { setAuthToken } from './utils/setAuthToken'

dayjs.extend(utc)

const LazyLogin = lazy(() => import('./modules/Login/Login'))
const LazyLoginAs = lazy(() => import('./modules/Login/LoginAs'))
const LazyHomepage = lazy(() => import('./modules/Layout/Homepage'))

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_ENV === 'development') {
  // eslint-disable-next-line no-undef
  axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`
} else {
  axios.defaults.baseURL = '/api/v1'
}

const queryClient = new QueryClient()

function App() {
  const { mode, isAuth, userId } = useSelector((state) => ({
    mode: state.Theme.mode,
    isAuth: state.User.isAuth,
    userId: state?.User?.user?.id,
  }))

  const dispatch = useDispatch()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const mode = localStorage.getItem('mode')
    dispatch(changeTheme(mode ?? 'light'))
  }, [])

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    if (sessionStorage.getItem('TSToken')) {
      setAuthToken(sessionStorage.getItem('TSToken'))
      const decoded = jwtDecode(sessionStorage.getItem('TSToken'))
      dispatch(setCurrentUser({ isAuth: true, user: decoded }))
      const currentTime = Date.now() / 1000
      if (decoded.exp < currentTime) {
        dispatch(setCurrentUser({ isAuth: false, user: {} }))
      }
    }

    setIsReady(true)
    setAxiosInterceptors()
  }

  return (
    <QueryClientProvider client={queryClient}>
      <WebSocketProvider>
        <ThemeProvider theme={theme(mode)}>
          <CssBaseline />
          <GlobalModal />
          <Suspense fallback={<Loader isFullScreen={true} />}>
            <Toast stacked />
            {isReady && (
              <Routes>
                <Route path='/login-as' element={<LazyLoginAs />} />
                <Route path='/login' element={<LazyLogin />} />
                <Route
                  path='*'
                  element={
                    <ProtectedRoute isAuth={isAuth}>
                      <LazyHomepage />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            )}
          </Suspense>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      </WebSocketProvider>
    </QueryClientProvider>
  )
}

export default App

import { InventoryStatus } from '../constants/Inventory.constants'

export const getVinFilterFromPathname = (pathname) => {
  const filters = { rowStatus: 'A' }

  switch (pathname) {
    case '/retail-inventory/in-stock':
      filters['notin.inventories.status'] = [InventoryStatus.SOLD, InventoryStatus.WHOLESALE]
      filters.isWholesale = false
      break
    case '/retail-inventory/sold':
      filters.status = InventoryStatus.SOLD
      filters.isWholesale = false
      break
    case '/wholesale/in-stock':
      filters['notin.inventories.status'] = [InventoryStatus.SOLD, InventoryStatus.WHOLESALE]
      filters.isWholesale = true
      break
    case '/wholesale/sold':
      filters.status = InventoryStatus.SOLD
      filters.isWholesale = true
      break
    default:
      break
  }

  return filters
}

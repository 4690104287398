import { io } from 'socket.io-client'

class WebsocketService {
  constructor() {
    if (WebsocketService.instance) {
      return WebsocketService.instance
    }

    this.socket = null
    WebsocketService.instance = this
  }

  connectSocket({ url, data }) {
    return new Promise((resolve, reject) => {
      const token = sessionStorage.getItem('TSToken')

      if (!token) {
        return reject(new Error('No token found in session storage.'))
      }

      if (this.socket && this.socket.connected) {
        console.log('Socket already connected:', this.socket.id)
        return resolve(this.socket)
      }

      console.log('Connecting socket...')
      this.socket = io(url, {
        query: {
          token: `Bearer ${token}`,
          ...data,
        },
        transports: ['websocket'],
        autoConnect: true,
        secure: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 5000,
      })

      this.socket.on('connect', () => {
        console.log('Connected to WebSocket:', this.socket.id)
        resolve(this.socket)
      })

      this.socket.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error)
        reject(error)
      })

      // Optional: Handle other WebSocket events as needed
      this.socket.on('disconnect', (reason) => {
        console.log('WebSocket disconnected:', reason)
      })

      this.socket.on('error', (error) => {
        console.error('WebSocket error:', error)
      })
    })
  }

  disconnectSocket(socket) {
    if (socket) {
      socket.disconnect()
      console.log('WebSocket disconnected.')
      this.socket = null // Reset the socket instance
    }
  }

  isConnected() {
    return Boolean(this?.socket?.connected)
  }
}

const instance = new WebsocketService()
export default instance

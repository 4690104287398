export const GetReadyStatus = {
  NONE: null,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
}

export const ReservationStatus = {
  ACTIVE: 1,
  EXPIRED: 2,
  CANCELLED: 3,
}
export const InventoryStatus = {
  UNKNOWN: null,
  PENDING_HOLD: 1,
  APPROVED_HOLD: 2,
  IN_STOCK: 3,
  PENDING_QUOTE: 4,
  APPROVED_QUOTE: 5,
  PENDING_CLOSE: 6,
  SOLD: 7,
  RESERVED: 8,
  IN_TRANSIT: 9,
  WHOLESALE: 10,
}

export const getInventoryStatusOptions = (salespersons = []) => {
  const salespersonsNames = salespersons?.length ? ' - ' + salespersons.map((s) => s.name).join(', ') : ''

  return [
    {
      name: `Pending Hold${salespersonsNames}`,
      id: InventoryStatus.PENDING_HOLD,
    },
    {
      name: `Approved Hold${salespersonsNames}`,
      id: InventoryStatus.APPROVED_HOLD,
    },
    { name: 'In Stock', id: InventoryStatus.IN_STOCK },
    { name: 'In Transit', id: InventoryStatus.IN_TRANSIT },
    {
      name: `Pending Quote${salespersonsNames}`,
      id: InventoryStatus.PENDING_QUOTE,
    },
    {
      name: `Approved Quote${salespersonsNames}`,
      id: InventoryStatus.APPROVED_QUOTE,
    },
    {
      name: `Pending Close${salespersonsNames}`,
      id: InventoryStatus.PENDING_CLOSE,
    },
    { name: 'Sold', id: InventoryStatus.SOLD },
    { name: 'Reserved', id: InventoryStatus.RESERVED },
    { name: 'Wholesale', id: InventoryStatus.WHOLESALE },
  ]
}

export const getInventoryStatus = (statusId, salespersons = []) => {
  const salespersonsNames = salespersons?.length ? ` - ${salespersons.map((s) => s.name).join(', ')}` : ''

  const statusMap = {
    [InventoryStatus.PENDING_HOLD]: `Pending Hold${salespersonsNames}`,
    [InventoryStatus.APPROVED_HOLD]: `Approved Hold${salespersonsNames}`,
    [InventoryStatus.IN_STOCK]: 'In Stock',
    [InventoryStatus.IN_TRANSIT]: 'In Transit',
    [InventoryStatus.PENDING_QUOTE]: `Pending Quote${salespersonsNames}`,
    [InventoryStatus.APPROVED_QUOTE]: `Approved Quote${salespersonsNames}`,
    [InventoryStatus.PENDING_CLOSE]: `Pending Close${salespersonsNames}`,
    [InventoryStatus.SOLD]: 'Sold',
    [InventoryStatus.RESERVED]: 'Reserved',
    [InventoryStatus.WHOLESALE]: 'Wholesale',
  }

  return statusMap[statusId] || 'Unknown'
}

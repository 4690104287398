import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AwsBucket } from '../../constants/Aws.constants'
import { getFiles } from '../../utils/getFiles'
import { toastNotification, toastType } from '../../utils/toast'
import { QuoteQueryKey } from '../Quotes/SingleQuote/singleQuoteApiHooks'
import { TaskQueryKey } from '../Task/taskApiHooks'
import { mapApiResponseToInventory, setMultipleValuesReactForm } from './types/utils'

export const InventoryQueryKey = {
  INVENTORIES: 'inventories',
  INVENTORY: 'inventory',
  INVENTORY_SPECS: 'inventory-specs',
  GET_READY: 'get-ready',
  GET_READY_MANAGERS: 'get-ready-managers',
  GET_READY_PDF: 'get-ready-pdf',
  INVENTORY_QUOTES: 'inventory-quotes',
  WHOLESALE: 'wholesale',
  IN_STOCK: 'in-stock',
  RESERVED: 'reserved',
  REPAIRS: 'repairs',
  DOCUMENTS: 'documents',
  IMAGES: 'images',
  TITLES: 'titles',
  CAN_TRANSFER_TO_RETAIL: 'can-transfer-to-retail',
}

//INVENTORIES
const addInventory = (data) => {
  return axios.post('/inventories', data)
}

export const useAddInventory = (callback) => {
  return useMutation(addInventory, {
    onSuccess: (data) => {
      if (typeof callback == 'function' && data?.data?.id) {
        callback(data?.data?.id)
      }
    },
  })
}

const getInventories = (options) => {
  return axios.get(`/inventories`, { params: { options } })
}

export const useInventories = (options) => {
  return useQuery([InventoryQueryKey.INVENTORIES, options], () => getInventories(options), {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
  })
}

// export const useGetInStockInventories = (options) => {
//   return useQuery([InventoryQueryKey.IN_STOCK, options], () => getInventories(options), {
//     keepPreviousData: true,
//     refetchOnWindowFocus: false,
//   })
// }

// export const useGetWholesaleInventories = (options) => {
//   return useQuery([InventoryQueryKey.WHOLESALE, options], () => getInventories(options), {
//     keepPreviousData: true,
//     refetchOnWindowFocus: false,
//   })
// }

const getInventory = (inventoryId) => {
  return axios.get(`/inventories/${inventoryId}`)
}

export const useGetInventory = (inventoryId, setValue) => {
  return useQuery([InventoryQueryKey.INVENTORY, inventoryId], () => getInventory(inventoryId), {
    refetchOnWindowFocus: false,
    enabled: !!inventoryId,
    onSuccess: ({ data }) => {
      if (typeof setValue == 'function') {
        const values = mapApiResponseToInventory(data)
        setMultipleValuesReactForm(values, setValue)
      }
    },
  })
}

const updateInventory = (data) => {
  return axios.patch(`/inventories/${data.id}`, data)
}

export const useUpdateInventory = ({ inventoryId, onError }) => {
  const queryClient = useQueryClient()
  return useMutation(updateInventory, {
    onSuccess: () => {
      toastNotification({ message: 'Inventory Updated', type: 'success' })
      queryClient.invalidateQueries([InventoryQueryKey.INVENTORY, inventoryId])
      toastNotification({ message: 'Changes Saved', type: toastType.success })
    },
    onError: (error) => {
      if (typeof onError == 'function' && Object.keys(error.response.data.message).length) {
        onError(error.response.data.message)
      }
    },
  })
}

const transferInventory = (data) => {
  return axios.patch(`/inventories/${data.id}/transfer`, data)
}

export const useTransferToRetail = ({ callbacks }) => {
  const queryClient = useQueryClient()
  return useMutation(({ id, transferToRetail }) => transferInventory({ id, transferToRetail }), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([InventoryQueryKey.INVENTORIES])
      queryClient.invalidateQueries([InventoryQueryKey.WHOLESALE])
      queryClient.invalidateQueries([InventoryQueryKey.INVENTORY, variables.id])
      toastNotification({ message: 'Inventory Updated', type: 'success' })
      callbacks?.onSuccess?.()
    },
  })
}

const getInventorySpecifications = (inventoryId) => {
  return axios.get(`/inventories/${inventoryId}/specs`)
}

export const useInventorySpecs = (inventoryId, options) => {
  return useQuery([InventoryQueryKey.INVENTORY_SPECS, inventoryId], () => getInventorySpecifications(inventoryId), {
    enabled: !!inventoryId && options?.enabled,

    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// DOCUMENTS
const getUploadedDocuments = async (inventoryId, options) => {
  return axios.get(`/inventories/${inventoryId}/documents`, { params: { options } })
}

export const useUploadedDocuments = (inventoryId, options) => {
  return useQuery(['documents', options], () => getUploadedDocuments(inventoryId, options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

const downloadAllDocuments = async (inventoryId) => {
  return getFiles({ url: `/inventories/${inventoryId}/files/download`, keys: null })
}

export const useDownloadAllDocuments = (inventoryId) => {
  return useMutation(() => downloadAllDocuments(inventoryId), {
    onSuccess: () => {
      toastNotification({ message: 'Documents Downloaded', type: toastType.success })
    },
  })
}

// DELETE DOCUMENT
const deleteSingleDocument = async (key) => {
  const response = await axios.delete(`/inventories/documents/${key}`)
  return response.data
}

export const useDeleteSingleDocument = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteSingleDocument, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['documents'])
      toastNotification({ message: 'Successfully deleted!', type: toastType.success })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// REPAIRS
const addRepair = ({ data, inventoryId }) => {
  return axios.post(`/inventories/${inventoryId}/repairs`, data)
}

export const useAddRepair = () => {
  const queryClient = useQueryClient()

  return useMutation(addRepair, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['repairs'])
      toastNotification({ message: 'Repair Added!', type: toastType.success })
    },
  })
}

const getRepairs = async (inventoryId, options) => {
  const response = await axios.get(`/inventories/${inventoryId}/repairs`, { params: { options } })

  return response
}

export const useGetRepairs = (inventoryId, options) => {
  return useQuery(['repairs', options, inventoryId], () => getRepairs(inventoryId, options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}

const updateRepair = (data) => {
  return axios.patch(`/inventories/${data.inventoryId}/repairs/${data.id}`, data)
}

export const useUpdateRepair = () => {
  const queryClient = useQueryClient()
  return useMutation(updateRepair, {
    onSuccess: () => {
      queryClient.invalidateQueries(['repairs'])
      toastNotification({ message: 'Changes Saved', type: toastType.success })
    },
  })
}

const deleteRepair = async (id, inventoryId) => {
  const response = await axios.delete(`/inventories/${inventoryId}/repairs/${id}`)
  return response.data
}

export const useDeleteRepair = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteRepair, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([InventoryQueryKey.REPAIRS])
      toastNotification({ message: 'Successfully deleted!', type: toastType.success })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// STOCK NUMBER
const getStockNumber = async () => {
  const response = await axios.get('/inventories/next-stock-number')

  return +response.data
}

export const useGetStockNumber = () => {
  return useQuery([InventoryQueryKey.STOCK_NUMBER], getStockNumber, {
    refetchOnWindowFocus: false,
  })
}

const checkStockNumber = async ({ stockNumber }) => {
  const data = await axios.get(`/inventories/stock-number/is-valid/${stockNumber}`)
  return data.data
}

export const useCheckStockNumber = (callbackOnSuccess) => {
  return useMutation(checkStockNumber, {
    onSuccess: (data) => {
      callbackOnSuccess(data)
      if (!data) {
        toastNotification({
          message: 'The stock number is not available. Please try a different one.',
          type: toastType.error,
        })
      }
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// GET READY
const createGetReady = ({ data, inventoryId }) => {
  return axios.post(`/inventories-get-ready/${inventoryId}`, data)
}

export const useCreateGetReady = () => {
  const queryClient = useQueryClient()

  return useMutation(({ data, inventoryId }) => createGetReady({ data, inventoryId }), {
    onSuccess: async (response, variables) => {
      variables?.callbacks?.onSuccess?.(response.data)
      await queryClient.invalidateQueries([InventoryQueryKey.GET_READY])
      await queryClient.invalidateQueries(QuoteQueryKey.QUOTE_INVENTORIES)
      toastNotification({ message: 'Get Ready Added!', type: toastType.success })
    },
  })
}

const findAllInventoryGetReady = async (inventoryId, options) => {
  const response = await axios.get(`/inventories-get-ready/${inventoryId}`, { params: { options } })

  return response
}

export const useFindAllInventoryGetReady = (inventoryId, options) => {
  return useQuery([InventoryQueryKey.GET_READY, options, inventoryId], () => findAllInventoryGetReady(inventoryId, options), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

const deleteInventoryGetReady = async (id) => {
  const response = await axios.delete(`/inventories-get-ready/${id}`)
  return response.data
}

export const useDeleteInventoryGetReady = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteInventoryGetReady, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([InventoryQueryKey.GET_READY])
      await queryClient.invalidateQueries(QuoteQueryKey.QUOTE_INVENTORIES)
      toastNotification({ message: 'Successfully deleted!', type: toastType.success })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

const updateGetReady = (data) => {
  return axios.patch(`/inventories-get-ready/${data.poNumber}`, data)
}

export const useUpdateGetReady = () => {
  const queryClient = useQueryClient()
  return useMutation(updateGetReady, {
    onSuccess: () => {
      queryClient.invalidateQueries([InventoryQueryKey.GET_READY])
      toastNotification({ message: 'Changes Saved', type: toastType.success })
    },
  })
}

const getReadyPdfData = async (poNumber) => {
  const response = await axios.get(`/inventories-get-ready/${poNumber}/pdf`)
  return response.data
}

export const useGetReadyPdfData = (poNumber) => {
  return useQuery([InventoryQueryKey.GET_READY_PDF, poNumber], () => getReadyPdfData(poNumber), {
    refetchOnWindowFocus: false,
    enabled: !!poNumber,
  })
}

const sendGetReadyEmail = async (poNumber, data, file) => {
  return axios.post(
    `/inventories-get-ready/${poNumber}/send-email`,
    { data, file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}

export const useSendGetReadyEmail = ({ onSuccess }) => {
  return useMutation(({ poNumber, data, file }) => sendGetReadyEmail(poNumber, data, file), {
    onSuccess: () => {
      onSuccess?.()
      toastNotification({ message: 'Email sent', type: toastType.success })
    },
  })
}

// GET READY QUOTE
// should be one quote per inventory??
const getInventoryQuotes = async (inventoryId) => {
  const response = await axios.get(`/inventories-get-ready/${inventoryId}/quotes`)
  return response.data
}

export const useGetInventoryQuotes = (inventoryId) => {
  return useQuery([InventoryQueryKey.INVENTORY_QUOTES, inventoryId], () => getInventoryQuotes(inventoryId), {
    refetchOnWindowFocus: false,
    enabled: !!inventoryId,
  })
}

const changeGetReadyStatus = (data) => {
  return axios.patch(`/inventories-get-ready/${data.poNumber}/status`, data)
}

export const useGetReadyStatusChange = ({ onSuccess }) => {
  const queryClient = useQueryClient()
  return useMutation(changeGetReadyStatus, {
    onSuccess: (data) => {
      onSuccess?.(data?.data)
      queryClient.invalidateQueries([InventoryQueryKey.GET_READY])
      queryClient.invalidateQueries(TaskQueryKey.TASKS)
      toastNotification({ message: 'Status Changed', type: toastType.success })
    },
  })
}

const getReadyManagers = async (poNumber) => {
  if (!poNumber) return []
  const response = await axios.get(`/inventories-get-ready/${poNumber}/managers`)
  return response.data
}

export const useGetReadyManagers = (poNumber) => {
  return useQuery([InventoryQueryKey.GET_READY_MANAGERS, poNumber], () => getReadyManagers(poNumber), {
    refetchOnWindowFocus: false,
  })
}

// Upload and Show Images
const uploadFiles = async (data, callbacks) => {
  let inventoryId = null
  let isTitle = false
  let isDocument = false

  for (const [key, value] of data.entries()) {
    if (key === 'inventoryId') {
      const inventoryIdObject = JSON.parse(value)
      inventoryId = inventoryIdObject.inventoryId
    }
    if (key === 'isTitle') {
      const isTitleObject = JSON.parse(value)
      isTitle = isTitleObject.isTitle
    }
    if (key === 'isDocument') {
      const isDocumentObject = JSON.parse(value)
      isDocument = isDocumentObject.isDocument
    }
  }

  const baseUrl = `/inventories/${inventoryId}`
  const endpoint = isTitle ? '/titles' : isDocument ? '/documents' : '/images'

  const response = await axios.post(`${baseUrl}${endpoint}`, data, {
    onUploadProgress: (progress) => {
      const percentage = Math.round((progress.loaded / progress.total) * 100)
      callbacks?.onProgress?.(percentage)
    },
  })
  return response.data
}

export const useUploadFiles = (callbacks = {}) => {
  const queryClient = useQueryClient()

  return useMutation((data) => uploadFiles(data, callbacks), {
    onSuccess: (data) => {
      callbacks?.onSuccess?.(data)
      toastNotification({ message: 'Upload Successful!', type: toastType.success })
      queryClient.invalidateQueries([InventoryQueryKey.INVENTORY])
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

const fileOperation = async ({ formData, action, inventoryId, key, type, onProgressCb }) => {
  let response
  switch (action) {
    case 'delete':
      response = await axios.delete(`/inventories/${inventoryId}/files/${key}`, { params: { type } })
      break
    case 'replace':
      formData.append('type', type)
      response = await axios.put(`/inventories/${inventoryId}/files/${key}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onDownloadProgress: (progress) => {
          const percentage = Math.round((progress.loaded / progress.total) * 100)
          onProgressCb && onProgressCb(percentage)
        },
        onUploadProgress: (progress) => {
          const percentage = Math.round((progress.loaded / progress.total) * 100)
          onProgressCb && onProgressCb(percentage)
        },
      })
      break
    case 'download':
      getFiles({ url: `/documents/download?bucket=${AwsBucket.images}`, keys: key, data: { documentKey: key } }, { allowDownload: true })
      return
    default:
      toastNotification({ message: 'Invalid action', type: toastType.error })
  }
  return response?.data
}

export const useFileOperation = ({ onProgressCb, onSuccessCb }) => {
  const queryClient = useQueryClient()
  return useMutation((data) => fileOperation({ ...data, onProgressCb }), {
    onSuccess: (data) => {
      queryClient.invalidateQueries([InventoryQueryKey.INVENTORY])
      onSuccessCb && onSuccessCb(data.message)
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

// RESERVE TRUCK
const reserveTruck = (data) => {
  return axios.post('/inventories/reserve-truck', data)
}

export const useReserveTruck = (inventoryId) => {
  const queryClient = useQueryClient()

  return useMutation(reserveTruck, {
    onSuccess: () => {
      queryClient.invalidateQueries([InventoryQueryKey.INVENTORY, `${inventoryId}`])
      toastNotification({ message: 'You have successfully reserved a truck.', type: toastType.success })
    },
  })
}

const cancelReservation = async (data) => {
  return axios.post(`/inventories/cancel-reservation`, data)
}

export const useCancelReservation = (inventoryId) => {
  const queryClient = useQueryClient()

  return useMutation(cancelReservation, {
    onSuccess: async () => {
      queryClient.invalidateQueries(['inventory', `${inventoryId}`])
      toastNotification({ message: 'Successfully cancelled!', type: toastType.success })
    },
    onError: (error) => {
      if (error.response?.data?.message) {
        toastNotification({ message: error.response?.data?.message, type: toastType.error })
      }
    },
  })
}

const getReservationHistory = (options) => {
  return axios.get(`/inventories/reservation-history`, { params: { options } })
}

export const useGetReservationHistory = (options) => {
  return useQuery(['history', options.pagination.page], () => getReservationHistory(options), {
    refetchOnWindowFocus: false,
  })
}

const getReservStatus = () => {
  return axios.get(`/inventories/reserve-status`)
}

export const useGetReservStatus = (inventoryId) => {
  return useQuery(['reserve-status', inventoryId], () => getReservStatus(), {
    refetchOnWindowFocus: false,
  })
}

import { createSlice } from '@reduxjs/toolkit'
import { CHAT_ROOM_ACTION } from '../constants/Chats.constants'

const userState = {
  isAuth: false,
  user: {},
  chatRooms: [],
}

// export const loginUser = createAsyncThunk('user/loginUser', async (loginData) => {
//   const { data } = await axios.post('/users/login', loginData)
//   sessionStorage.setItem('TSToken', data.token)
//   setAuthToken(data.token)
//   const decoded = jwt_decode(data.token)

//   return decoded
// })

const UserSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.isAuth = payload.isAuth
      state.user = payload.user
      const storedRooms = sessionStorage.getItem('chatRooms') ? JSON.parse(sessionStorage.getItem('chatRooms')) : []
      state.chatRooms = Array.from(new Set([...storedRooms]))
    },
    logoutUser: (state) => {
      state.isAuth = false
      state.user = {}
      state.chatRooms = []
      sessionStorage.setItem('chatRooms', '')
      sessionStorage.setItem('TSToken', '')
    },
    setChatRooms: (state, { payload }) => {
      const storedRooms = sessionStorage.getItem('chatRooms') ? JSON.parse(sessionStorage.getItem('chatRooms')) : []
      state.chatRooms = Array.from(new Set([...storedRooms, ...payload]))
    },
    clearChatRooms: (state) => {
      state.chatRooms = []
      sessionStorage.setItem('chatRooms', '')
    },
    manageChatRooms: (state, { payload }) => {
      if (payload.action === CHAT_ROOM_ACTION.JOIN) {
        state.chatRooms = Array.from(new Set([...state.chatRooms, payload.room]))
      } else if (payload.action === CHAT_ROOM_ACTION.LEAVE) {
        state.chatRooms = state.chatRooms.filter((room) => room !== payload.room)
      }

      sessionStorage.setItem('chatRooms', JSON.stringify(state.chatRooms))
    },
  },
  // extraReducers: {
  //   [loginUser.fulfilled]: (state, { payload }) => {
  //     return { isAuth: true, user: payload }
  //   },
  // },
})

export const { setCurrentUser, logoutUser, setChatRooms, manageChatRooms, clearChatRooms } = UserSlice.actions

export default UserSlice.reducer
